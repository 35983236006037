// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-adobe-js": () => import("./../../src/pages/adobe.js" /* webpackChunkName: "component---src-pages-adobe-js" */),
  "component---src-pages-bt-js": () => import("./../../src/pages/bt.js" /* webpackChunkName: "component---src-pages-bt-js" */),
  "component---src-pages-facebook-js": () => import("./../../src/pages/facebook.js" /* webpackChunkName: "component---src-pages-facebook-js" */),
  "component---src-pages-figma-js": () => import("./../../src/pages/figma.js" /* webpackChunkName: "component---src-pages-figma-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

